 // Routes
import { useEffect } from 'react';
// React Hooks
import Router from './routes';
// AuthContest
import useAuth from './hooks/useAuth';
// Redux
import { dispatch } from './redux/store';
import { userActions } from './redux/slices/allUsers';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  const { user } = useAuth();
  const { fetchUsers } = userActions;

  // --------------------------dispatch data on app mount---------------------
  useEffect(() => {
    if (user !== null) {
      dispatch(fetchUsers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
