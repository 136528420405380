import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import allUsers from './slices/allUsers';
import { 
  getQuestionsReducer, 
  getResultsReducer,
  getTablesReducer, 
  getTableReducer,
  getPieChartsReducer, 
  botNameReducer, 
  chatbotIdReducer, 
  createQuestionReducer, 
  createResultReducer,
  createTableReducer,
  getUsersReducer
  // updateQuestionReducer
} from './slices/chatBot';
import { webSocketStatusReducer, storeWSResponseReducer, onSendMessageReducer } from './slices/webSocket';
import optionsReducer from './slices/handleOptions';
import tableReducer from './slices/tableData';
import isChatbotPageReducer from './slices/isChatbotPage';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  allUsers,
  botName: botNameReducer,
  getQuestions: getQuestionsReducer,
  getResults: getResultsReducer,
  getTables: getTablesReducer,
  getTable: getTableReducer,
  getPieCharts: getPieChartsReducer,
  createQuestion: createQuestionReducer,
  // updateQuestion: updateQuestionReducer,
  createResult: createResultReducer,
  createTable: createTableReducer,
  chatbotId: chatbotIdReducer,
  webSocketStatus: webSocketStatusReducer,
  storeWSResponse: storeWSResponseReducer,
  onSendMessage: onSendMessageReducer,
  handleOptions: optionsReducer,
  addTableData: tableReducer,
  isChatbotPage: isChatbotPageReducer,
  getUsers: getUsersReducer,
});

export { rootPersistConfig, rootReducer };
