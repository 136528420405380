import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup, Card, CardContent, Typography, 
  FormControlLabel, Checkbox, Avatar, Box, Container, Table, TableHead } from '@mui/material';
import { addOption, removeOption } from '../../../redux/slices/handleOptions';
//
import Scrollbar from '../../../components/Scrollbar';
import LightboxModal from '../../../components/LightboxModal';
import ChatMessageItem from './ChatMessageItem';
import ChatMultiOptionsInput from './ChatMultiOptionsInput';


const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  fontFamily: 'aptos',
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
  fontFamily: 'aptos',
}));


const MultiSelectCard = ({ option }) => {
  const [currOption, setCurrOption] = useState("");
  const selectedOptions = useSelector(state => state.handleOptions);
  const dispatch = useDispatch();

  const handleCheckboxChange = (e, option) => {
    e.preventDefault();
    setCurrOption(option);
  };

  useEffect(() => {
    if (currOption && currOption.length > 0) {
      const isAvailable = selectedOptions?.includes(currOption);

      if (isAvailable){
        dispatch(removeOption(currOption));
      }
      else{
        dispatch(addOption(currOption));
      }
      setCurrOption("");
    }
  }, [currOption]);


  return (
    <Card sx={{ p: 0, m: 2, maxWidth: 300 }}>
      <CardContent sx={{p: 0, paddingLeft: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <FormGroup sx={{p: 0}}>
          <FormControlLabel
            sx={{paddingTop: 2}}
            control={
              <Checkbox
                checked={selectedOptions?.includes(option)}
                onChange={(e) => handleCheckboxChange(e, option)}
                sx={{p: 0, paddingLeft: 1}}
              />
            }
            label={<Typography variant="body2" sx={{fontFamily: 'aptos', p: 0, paddingLeft: 1}} >{option}</Typography>}
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
};


export default function ChatMessageList({ conversation, options=[], logo="" , botName }) {
  const scrollRef = useRef(null);

  const ChatMessageItemMemo = React.memo(ChatMessageItem);
  const MultiSelectCardMemo = React.memo(MultiSelectCard);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [conversation]);

  const imagesLightbox = conversation
    .filter((messages) => messages.contentType === 'image')
    .map((messages) => messages.body);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 'auto', width: 'auto', fontFamily: 'cursive'}}>
        <RootStyle>
          <Box sx={{ display: 'flex', }}>
            <Avatar alt={'sender_img'} src={logo} sx={{ width: 32, height: 32, mr: 2 }} />
            <div>
              <InfoStyle variant="caption">

                {botName}, &nbsp;
                {formatDistanceToNowStrict(new Date(), {
                  addSuffix: true,
                })}
              </InfoStyle>
              <ContentStyle>
                <Typography variant="body2" sx={{ fontFamily: 'aptos' }}>
                  Hello User! Welcome to {botName}
                </Typography>
              </ContentStyle>
            </div>
          </Box>
        </RootStyle>
        {conversation.map((message , index) => (
          <ChatMessageItemMemo
            key={index}
            message={message}
            sender={message.senderId}
            senderName={message.senderName}
            onOpenLightbox={handleOpenLightbox}
            logo={logo}
          />
        ))}
        {conversation[conversation.length -1].type ==='multiselect' && options.length >= 1 &&
          options.map((option,id) => (
              <MultiSelectCardMemo 
                key={id}
                option={option} 
              />
          ))
        }
      </Scrollbar>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox} 
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}
