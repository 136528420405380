import { createSlice } from "@reduxjs/toolkit";

const isChatbotPageSlice = createSlice({
    name: 'isChatbotPage',
    initialState: true,
    reducers: {
        updateState: (state, action) => {
            state = action.payload;
            return action.payload
        }
    }
});

export const {updateState} = isChatbotPageSlice.actions;
export default isChatbotPageSlice.reducer;