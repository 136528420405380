import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
    name: 'table',
    initialState: [],
    reducers: {
        addTableData: (state, action) => {
            state.push(action.payload);
        },
        addTableFields: (state, action) => {
            state[0].rows = action.payload;
        },
        removeTableData: (state, action) => {
            state = [];
        },
    },
});

export const { addTableData, addTableFields, removeTableData } = tableSlice.actions;
export default tableSlice.reducer;