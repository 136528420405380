import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Container, Table, TableHead, Typography } from '@mui/material';
// components
import Image from '../../../components/Image';
// import DisplayTable from 'src/pages/dashboard/DisplayTable';
import DisplayTable from '../../../pages/dashboard/DisplayTable';
// import Table from 'src/theme/overrides/Table';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  fontFamily: 'aptos',
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
  fontFamily: 'aptos',
}));

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message, sender,senderName,  onOpenLightbox, logo }) {
  const [msg,setMsg]=React.useState(message.message)

  const senderDetails =
    message.senderId === '8864c717-587d-472a-929a-8e5f298024da-0'
      ? { type: 'me' }
      : { avatar: sender?.avatar, name: sender?.name };

  const isMe = senderDetails.type === 'me';

  const isImage = message.type === 'image';

  const isTable = message.type === 'table';

  const firstName = senderName;

  React.useEffect(() => {
    if (message.contentType === 'integer') {
      setMsg((parseInt(message.message, 10)).toLocaleString('en-IN'));
    } else if (message.contentType === 'phone') {
      setMsg((parseFloat(message.message, 10)));
    } else {
      setMsg(message.message);
    }
  }, [message]);
  
  return (
    <>
      {message?.body === 'null' ? null : (
        <>
          <RootStyle>
            <Box
              sx={{
                display: 'flex',
                ...(isMe && {
                  ml: 'auto',
                }),
              }}
            >
              {senderDetails.type !== 'me' && (
                <Avatar alt={senderDetails.name} src={logo} sx={{ width: 32, height: 32, mr: 2 }} />
              )}

              <div>
                <InfoStyle
                  variant="caption"
                  sx={{
                    ...(isMe && { justifyContent: 'flex-end' }),
                  }}
                >
                  {!isMe && `${firstName},`}&nbsp;
                  {formatDistanceToNowStrict(new Date(message.createdAt), {
                    addSuffix: true,
                  })}
                </InfoStyle>

                <ContentStyle
                  sx={{
                    ...(isMe && {
                      color: 'grey.800',
                      bgcolor: 'primary.lighter',
                      fontFamily: 'aptos',
                      fontWeight: 'normal',
                    }),
                    ...((isImage || isTable) && {
                      p: 0,
                      '*::-webkit-scrollbar': {
                        height: '4px',
                      },
                      '*::-webkit-scrollbar-track': {
                        background: '#E4EFEF',
                      },
                      '*::-webkit-scrollbar-thumb': {
                        background: '#1D388F61',
                        borderRadius: '2px',
                      },
                    }),
                  }}
                >
                  {isImage ? (
                    <Image
                      alt="attachment"
                      src={message.body}
                      onClick={() => onOpenLightbox(message.body)}
                      sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                    />
                  ) : null}

                  {isTable ? (
                    <Container sx={{ maxWidth: '17rem', maxHeight: '50rem' }}>
                      <DisplayTable rows={message.message} />
                    </Container>
                  ) : null}

                  {!isTable && !isImage ? (
                    <Typography variant="body2" sx={{ fontFamily: 'aptos' }}>
                      {msg}
                    </Typography>
                  ) : null}
                  
                </ContentStyle>
              </div>
            </Box>
          </RootStyle>
        </>
      )}
    </>
  );
}
