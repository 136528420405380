// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
// redux
// import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
// const chatbotName = useSelector((state) => state.botName.botName);
const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------


  // MANAGEMENT
  // ----------------------------------------------------------------------
 
  // CHATBOT DETAILS
  // ----------------------------------------------------------------------
  {
    subheader: 'ChatBot',
    items: [
      {
        title: 'details',
        path: PATH_DASHBOARD.chatBotDetails.root,
        icon: ICONS.chat,
      },
      {
        title: 'questions',
        path: PATH_DASHBOARD.chatBotDetails.questions.root,
        icon: ICONS.chat,
        // children: [
        //   {title: 'create', path: PATH_DASHBOARD.chatBotDetails.questions.create}
        // ],
      },
      {
        title: 'result',
        path: PATH_DASHBOARD.chatBotDetails.result.root,
        icon: ICONS.chat,
      },
      {
        title: 'table',
        path: PATH_DASHBOARD.chatBotDetails.table.root,
        icon: ICONS.chat,
      },
      // {
      //   title: 'piechart',
      //   path: PATH_DASHBOARD.chatBotDetails.pieChart.root,
      //   icon: ICONS.chat,
      // },
      {
        title: 'Bot Settings',
        path: PATH_DASHBOARD.chatBotDetails.botSettings.root,
        icon: ICONS.chat,
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
 
];

export default navConfig;
