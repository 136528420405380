import { useEffect, useState } from 'react';
import { useLocation, Outlet, useParams } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack, Card } from '@mui/material';
import axios from 'axios';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import { ChatWindow } from '../../sections/@dashboard/chat';
import Page from '../../components/Page';

// ----------------------------------------------------------------------
export default function ChatbotLayout() {
  // const HOST_API = `http://localhost:3000`;
  const HOST_API = `https://api.richvikwealth.in`;

  const [bg, setBg] = useState('white');

  const { pathname } = useLocation();

  const { chatbotSlug } = useParams();

  useEffect(() => {
    async function fetchBackground() {
      const response = await axios.get(`${HOST_API}/get-chatbot-bg/${chatbotSlug}`);
      setBg(response?.data?.data?.bg);
    }

    fetchBackground();
  }, []);

  const { themeStretch } = useSettings();

  const backgroundStyle = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    opacity: 0.6,
  };

  return (
    <Page title="Chatbot" sx={{p: 1, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
      <div style={backgroundStyle} /> 
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{display: 'flex', justifyContent: 'center'}}>
        <Card sx={{ height: '90vh', display: 'flex', width: '430px' }}>
          <ChatWindow />
        </Card>
      </Container>
    </Page>
  );
}
