// botNameSlice.js

import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios  from 'axios';

const HOST_API = `https://api.richvikwealth.in`;
// const HOST_API = `http://localhost:3000`;

const initialState = {
  botName: null,
  questions: {
    allQuestions: [],
  },
  results: {
    allResults: [],
  },
  tables: {
    allTables: [],
    singleTable: [],
  },
  pieCharts: {
    allPieCharts: [],
  },
  users: {
    allUsers: [],
  },
  chatbotId: null,
};

const botNameSlice = createSlice({
  name: 'botName',
  initialState,
  reducers: {
    setBotName: (state, action) => {
      state.botName = action.payload;
    },
  },
});

const chatbotIdSlice = createSlice({
  name: 'chatbotId',
  initialState,
  reducers: {
    setChatbotId: (state, action) => {
      state.chatbotId = action.payload;
    },
  },
});

export const getChatBotSettings = createAsyncThunk('getChatBotSettings', async ({obj}) => {
  const { chatbotId } = obj;
  const response = await axios.get(`${HOST_API}/get-bot-settings/global-settings/${chatbotId}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  });
  console.log(response)
  return response.data;
});

export const updateChatBotSettings = createAsyncThunk('updateChatBotSettings', async ({obj}) => {
  const { chatbotId, globalVariables } = obj;
  const response = await axios.put(`${HOST_API}/get-bot-settings/global-settings/${chatbotId}`,
    { globalVariables },
    { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } },
  );
  return response;
});

export const getUserData = createAsyncThunk('getUsers', async ( {obj}) => {

  const { chatbotId, filterQuery, startDate, endDate } = obj;
  
  let searchQuery = '?';

  if(filterQuery !== ''){
    searchQuery += `&filterQuery=${filterQuery}`;
  }
  if(startDate !== ''){
    searchQuery += `&startDate=${startDate}`;
  }
  if(endDate !== ''){
    searchQuery += `&endDate=${endDate}`;
  }

  const response = await axios.get(`${HOST_API}/get-user-data/${chatbotId}${searchQuery}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  });


    return response;
});

export const fetchQuestions = createAsyncThunk('getQuestions/fetchQuestions', async (chatbotId) => {
  // try {
    const response = await axios.get(`${HOST_API}/get-question-list/${chatbotId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });
    return response;
  // } catch (error) {
  //   throw error;
  // }
});

export const createQuestionThunk = createAsyncThunk('getQuestions/createQuestion', async (obj) => {
  // try {
    const response = await axios.post(`${HOST_API}/create-question`, 
      obj,
      { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } },
    );
    return response;
  // } catch (error) {
  //   throw error;
  // }
});


export const updateQuestionThunk = createAsyncThunk('getQuestions/updateQuestion', async (obj) => {
    const response = await axios.post(`${HOST_API}/update-question`, 
      obj,
      { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } },
    );
    return response;
});

export const deleteQuestionThunk = createAsyncThunk('getQuestions/deleteQuestion', async (obj) => {
  const response = await axios.post(`${HOST_API}/delete-question/${obj._id}`,null,{
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  });
  return response;
});

export const fetchResults = createAsyncThunk('getResults/fetchResults', async (chatbotId) => {
  // try {
    const response = await axios.get(`${HOST_API}/get-result-list/${chatbotId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });
    return response;
  // } catch (error) {
  //   throw error;
  // }
});

export const createResultThunk = createAsyncThunk('getResults/createResult', async (obj) => {
  // try {
    const response = await axios.post(`${HOST_API}/create-result`, 
      obj,
      { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } },
    );
    return response;
  // } catch (error) {
  //   throw error;
  // }
});

export const updateResultThunk = createAsyncThunk('getResults/updateResult', async (obj) => {
    const response = await axios.post(`${HOST_API}/update-result`, 
      obj,
      { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } },
    );
    return response;
});

export const fetchTables = createAsyncThunk('getTables/fetchTables', async (chatbotId) => {
  // try {
    const response = await axios.get(`${HOST_API}/get-table-list/${chatbotId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });
    return response;
  // } catch (error) {
  //   throw error;
  // }
});

export const fetchTable = createAsyncThunk('getTable', async (tableId) => {
  const response = await axios.get(`${HOST_API}/get-table/${tableId}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  });
  return response;
});

export const createTableThunk = createAsyncThunk('getTables/createTable', async (obj) => {
  // try {
    const response = await axios.post(`${HOST_API}/create-table`, 
      obj,
      { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } },
    );
    return response;
  // } catch (error) {
  //   throw error;
  // }
});

export const fetchPieCharts = createAsyncThunk('getPieCharts/fetchPieCharts', async (chatbotId) => {
  // try {
    const response = await axios.get(`${HOST_API}/get-piechart-list/${chatbotId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });
    return response;
  // } catch (error) {
  //   throw error;
  // }
});

// const getQuestionsSlice = createSlice({
//   name: 'getQuestions',
//   initialState,
//   reducers: {
//     getQuestions: (state, action) => {
//       state.questions = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(fetchQuestions.fulfilled, (state, action) => {
//       // Merge the new questions with the existing ones
//       state.questions.allQuestions = action.payload;
//     });
//     builder.addCase(fetchQuestions.rejected, (state, action) => {
//       // Merge the error and other properties without affecting allQuestions
//       state.questions = {
//         ...state.questions,
//         error: action.error,
//         toast: { message: action?.error?.message, variant: 'error' },
//       };
//     });
//     builder.addCase(fetchQuestions.pending, (state) => {
//       // Merge loading with the existing allQuestions
//       state.questions = {
//         ...state.questions,
//         loading: true,
//         allQuestions: state.questions.allQuestions || [],
//       };
//     });
//   },
// });

const getUserSlice = createSlice({
  name: 'getUsers',
  initialState,
  reducers: {
    getUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.users = {allUsers: action.payload};
    });
    builder.addCase(getUserData.pending, (state, action) => {
      state.users = {loading: true, allUsers: state.users.allUsers || []};
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.users = {allUsers: state.users.allUsers};
    });
  },
});

const getQuestionsSlice = createSlice({
  name: 'getQuestions',
  initialState,
  reducers: {
    getQuestions: (state, action) => {
      state.questions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = {allQuestions: action.payload};
    });
    builder.addCase(fetchQuestions.rejected, (state, action) => {
      state.questions = {
        error: action.error,
        allQuestions: state.questions.allQuestions,
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(fetchQuestions.pending, (state) => {
      state.questions = {loading: true, allQuestions: state.questions.allQuestions || []}
    });

    builder.addCase(updateQuestionThunk.fulfilled, (state, action) => {      
      const newQuestionData = state?.questions?.allQuestions?.data?.data?.map((question) => question._id === action.payload.data.data._id ? action.payload.data.data : question);

      state.questions = {
        allQuestions: {data: {data: newQuestionData, message: `Questions found`}}
      };

    });
    builder.addCase(updateQuestionThunk.rejected, (state, action) => {
      state.questions = {
        error: action.error,
        allQuestions: state.questions.allQuestions,
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(updateQuestionThunk.pending, (state) => {
      state.questions = {...state.questions,loading: true, allQuestions: state.questions.allQuestions || []}
    });

    builder.addCase(deleteQuestionThunk.fulfilled, (state, action) => {
      const newQuestionData = state?.questions?.allQuestions?.data?.data?.filter((question) => question._id !== action.payload.data.data._id);
      state.questions = {
        allQuestions: {data: {data: newQuestionData, message: `Questions found`}}
      };
    }
    );
    builder.addCase(deleteQuestionThunk.rejected, (state, action) => {
      state.questions = {
        error: action.error,
        allQuestions: state.questions.allQuestions,
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(deleteQuestionThunk.pending, (state) => {
      state.questions = {...state.questions,loading: true, allQuestions: state.questions.allQuestions || []}
    });

    
  },
});

const createQuestionSlice = createSlice({
  name: 'createQuestion',
  initialState,
  // reducers: {
  //   createQuestion: (state, action) => {
  //     state.questions = action.payload;
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(createQuestionThunk.fulfilled, (state, action) => {
      state.questions = {allQuestions: [action.payload, ...state.questions.allQuestions]};
    });
    builder.addCase(createQuestionThunk.rejected, (state, action) => {
      state.questions = {
        error: action.error,
        allQuestions: state.questions.allQuestions,
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    // builder.addCase(createQuestionThunk.pending, (state) => {
    //   state.questions = {loading: true, allQuestions: state.questions.allQuestions || []}
    // });
  },
});


const getResultsSlice = createSlice({
  name: 'getResults',
  initialState,
  reducers: {
    getResults: (state, action) => {
      state.results = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action) => {
      state.results = {allResults: action.payload};
    });
    builder.addCase(fetchResults.rejected, (state, action) => {
      state.results = {
        error: action.error,
        allResults: [],
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(fetchResults.pending, (state) => {
      state.results = {loading: true, allResults: []}
    });
    builder.addCase(createResultThunk.fulfilled, (state, action) => {
      state.results = {allResults: [action.payload, ...state.results.allResults]};
    });
    builder.addCase(createResultThunk.rejected, (state, action) => {
      state.results = {
        error: action.error,
        allResults: [state.results.allResults],
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(createResultThunk.pending, (state) => {
      state.results = {loading: true, allResults: []}
    });

    builder.addCase(updateResultThunk.fulfilled, (state, action) => {      
      const newResultData = state?.results?.allResults?.data?.data?.map((result) => result._id === action.payload.data.data._id ? action.payload.data.data : result);
      state.results = {
        allResults: {data: {data: newResultData, message: `Questions found`}}
      };
    });
    builder.addCase(updateResultThunk.rejected, (state, action) => {
      state.results = {
        error: action.error,
        allResults: state.questions.allQuestions,
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(updateResultThunk.pending, (state) => {
      state.results = {...state.results, loading: true, allResults: state.results.allResults || []}
    });
  },
});

// const createResultSlice = createSlice({
//   name: 'createResult',
//   initialState,
//   reducers: {
//     createResult: (state, action) => {
//       state.results = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
    
//   },
// });

const getTablesSlice = createSlice({
  name: 'getTables',
  initialState,
  reducers: {
    getTables: (state, action) => {
      state.tables = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTables.fulfilled, (state, action) => {
      state.tables = {allTables: action.payload};
    });
    builder.addCase(fetchTables.rejected, (state, action) => {
      state.tables = {
        error: action.error,
        allTables: [],
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(fetchTables.pending, (state) => {
      state.tables = {loading: true, allTables: []}
    });
  },
});

const getTableSlice = createSlice({
  name: 'getTable',
  initialState,
  reducers: {
    getTable: (state, action) => {
      state.tables = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTable.fulfilled, (state, action) => {
      state.tables = {singleTable: action.payload};
    });
    builder.addCase(fetchTable.rejected, (state, action) => {
      state.tables = {
        error: action.error,
        singleTable: [],
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(fetchTable.pending, (state) => {
      state.tables = {loading: true, singleTable: []}
    });
  },
});

const createTableSlice = createSlice({
  name: 'createTable',
  initialState,
  reducers: {
    createTable: (state, action) => {
      state.tables = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTables.fulfilled, (state, action) => {
      state.tables = {allTables: [action.payload, ...state.tables.allTables]};
    });
    builder.addCase(fetchTables.rejected, (state, action) => {
      state.tables = {
        error: action.error,
        allTables: [state.tables.allTables],
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(fetchTables.pending, (state) => {
      state.tables = {loading: true, allTables: []}
    });
  },
});

const getPieChartsSlice = createSlice({
  name: 'getPieCharts',
  initialState,
  reducers: {
    getPieCharts: (state, action) => {
      state.pieCharts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPieCharts.fulfilled, (state, action) => {
      state.pieCharts = {allPieCharts: action.payload};
    });
    builder.addCase(fetchPieCharts.rejected, (state, action) => {
      state.pieCharts = {
        error: action.error,
        allPieCharts: [],
        toast: { message: action?.error?.message, variant: 'error' },
      }
    });
    builder.addCase(fetchPieCharts.pending, (state) => {
      state.pieCharts = {loading: true, allPieCharts: []}
    });
  },
});

export const { setBotName } = botNameSlice.actions;
export const { getQuestions } = getQuestionsSlice.actions;
export const { getUsers } = getUserSlice.actions;
export const { getResults } = getResultsSlice.actions;
export const { getTables } = getTablesSlice.actions;
export const { getTable } = getTableSlice.actions;
export const { getPieCharts } = getPieChartsSlice.actions;

// Creation
export const { createQuestion } = createQuestionSlice.actions;
// export const { updateQuestion } = updateQuestionSlice.actions;
export const { createResult } = getResultsSlice.actions;
export const { createTable } = createTableSlice.actions;

//
export const { setChatbotId } = chatbotIdSlice.actions;

// Reducers...
export const getQuestionsReducer = getQuestionsSlice.reducer;
export const getResultsReducer = getResultsSlice.reducer;
export const getUsersReducer = getUserSlice.reducer;
export const getTablesReducer = getTablesSlice.reducer;
export const getTableReducer = getTableSlice.reducer;
export const getPieChartsReducer = getPieChartsSlice.reducer;

// Creation
export const createQuestionReducer = createQuestionSlice.reducer;
// export const updateQuestionReducer = updateQuestionSlice.reducer;
export const createResultReducer = getResultsSlice.reducer;
export const createTableReducer = createTableSlice.reducer;

//
export const botNameReducer = botNameSlice.reducer;
export const chatbotIdReducer = chatbotIdSlice.reducer;
