import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Input, Divider, IconButton, InputAdornment, Button, Box } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// utils
import uuidv4 from '../../../utils/uuidv4';
// components
import Iconify from '../../../components/Iconify';
import { EmojiPicker } from '../../../components/emoji-picker';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  paddingTop: '0.5rem'
}));

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func,
};

export default function ChatMessageInput({ disabled, conversationId, onSend, options }) {
    const containerRef = useRef(null);

    const handleScroll = (scrollOffset) => {
        containerRef.current.scrollLeft += scrollOffset;
    };

  const handleClick = (option) => {
    if (onSend && conversationId) {
        onSend({
          conversationId,
          messageId: uuidv4(),
          message: option,
          conmten: 'text',
          attachments: [],
          createdAt: new Date(),
          senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        });
    }
    return '';
  }

//   const handleSend = () => {
//     if (!message) {
//       return '';
//     }
    
    // return setMessage('');
//   };

  return (
    <h1>hello</h1>

  );
}
