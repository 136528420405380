import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// import { HOST_API } from '../../config';

const HOST_API = `https://api.richvikwealth.in`;
// const HOST_API = `http://localhost:3000`;

const name = 'user';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const userActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

function createInitialState() {
  return {
    user: { allUsers: [] },
  };
}

function createExtraActions() {
  return {
    fetchUsers: fetchUsers(),
    deleteUsers: deleteUsers(),
    updateUser: updateUser(),
    createUser: createUser(),
  };
}

function fetchUsers() {
  return createAsyncThunk(`${name}/fetchUsers`, async () => {
    try{
      const response = await axios.get(`${HOST_API}/get-chatbot-list`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      return response;
    }
    catch (err){
      throw err.response.data;
    }
  });
}

function deleteUsers() {
  return createAsyncThunk(`${name}/deleteUsers`, async (userIds) => {
    try {
      const response = await axios.delete(
        HOST_API.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/users`),
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          data: {
            user_ids: userIds,
          },
        }
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function updateUser() {
  return createAsyncThunk(`${name}/updateUser`, async (obj) => {
    try {
      const response = await axios.post(`${HOST_API}/update-chatbot`,
        obj,
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function createUser() {
  return createAsyncThunk(`${name}/createUser`, async (obj) => {
    try {
      const response = await axios.post(`${HOST_API}/create-chatbot`,
        obj,
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function createExtraReducers() {
  return {
    ...fetchUsers(),
    ...deleteUsers(),
    ...updateUser(),
    ...createUser(),
  };

  function fetchUsers() {
    const { pending, fulfilled, rejected } = extraActions.fetchUsers;
    return {
      [pending]: (state) => {
        state.user = { loading: true, allUsers: [] };
      },
      [fulfilled]: (state, action) => {
        state.user = { allUsers: action.payload.data };
      },
      [rejected]: (state, action) => {
        state.user = {
          error: action.error,
          allUsers: [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }

  function deleteUsers() {
    const { pending, fulfilled, rejected } = extraActions.deleteUsers;
    return {
      [pending]: (state, action) => {
        state.user = {
          loading: true,
          allUsers: state?.user?.allUsers?.filter((user) => !action?.meta?.arg.includes(user.id)) || [],
        };
      },
      [fulfilled]: (state) => {
        state.user = {
          allUsers: state?.user?.allUsers,
          toast: { message: 'Account deletion successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.user = {
          error: action.error,
          allUsers: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }

  function updateUser() {
    const { pending, fulfilled, rejected } = extraActions.updateUser;
    return {
      [pending]: (state) => {
        state.user = { loading: true, allUsers: state?.user?.allUsers || [] };
      },
      [fulfilled]: (state, action) => {
        const newData = state?.user?.allUsers?.data.map((user) => user._id === action.payload.data.data._id ? action.payload.data.data : user);

        state.user = {
          loading: false,
          allUsers: {data: newData, message: "new chatbot updated"},
          toast: { message: 'User edition successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.user = {
          loading: false,
          error: action.error,
          allUsers: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
  function createUser() {
    const { pending, fulfilled, rejected } = extraActions.createUser;
    return {
      [pending]: (state) => {
        state.user = { allUsers: state?.user?.allUsers || [] };
      },
      [fulfilled]: (state, action) => {

        if (Array.isArray(state?.user?.allUsers)){
          state.user = {
            allUsers: [action.payload.data.data, ...state?.user?.allUsers],
            toast: { message: 'User creation successfull', variant: 'success'}
          };
        }
        else {
          state.user = {
            allUsers: [action.payload.data.data],
            toast: { message: 'User creation successfull', variant: 'success' },
          };
        }
      },
      [rejected]: (state, action) => {
        state.user = {
          error: action.error,
          allUsers: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
}

// Reducer

// Actions
