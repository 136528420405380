import React from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";

// -------------------------------------------
function TableMapper(row, index) {
    return (
        <>
            {
                index === 0 ?
                <TableHead>
                    <TableRow>
                        {row.map((cell,index) => (
                            <TableCell sx={{fontFamily: 'aptos'}} key={index}>
                                {cell?.value || cell}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            :
                <TableBody>
                    <TableRow>
                        {row.map((cell,index) => (
                            <TableCell sx={{fontFamily: 'aptos'}} key={index}>
                                {cell?.value || cell}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            }
        </>
    );
}

export default function DisplayTable(props) {
    const { rows } = props;
    if (rows && rows.length > 0){
        return (
            <>
                <TableContainer>
                    <Table sx={{fontFamily: 'aptos'}}>
                        {rows.map((row, index) => (
                            TableMapper(row, index)
                        ))}
                    </Table>
                </TableContainer>
            </>
        );
    }
};
