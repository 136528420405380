import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Input, Divider, IconButton, InputAdornment, Button, Box } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// utils
import uuidv4 from '../../../utils/uuidv4';
// components
import Iconify from '../../../components/Iconify';
import { EmojiPicker } from '../../../components/emoji-picker';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  paddingTop: '0.5rem',
}));

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func,
};

export default function ChatMessageInput({ disabled, options }) {
  const containerRef = useRef(null);

  const handleScroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  // console.log(options)
  return (
    <RootStyle sx={{ fontFamily: 'aptos' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" position="relative" overflow="hidden">
        <IconButton
          onClick={() => handleScroll(-200)}
          sx={{
            mx: 1,
            position: 'absolute',
            left: 0,
            zIndex: 1,
            transition: 'opacity 0.3s',
            opacity: 1,
          }}
        >
          <NavigateBeforeIcon />
        </IconButton>

        <Divider orientation="vertical" flexItem />

        <Box
          ref={containerRef}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            maxWidth: '30rem',
            flex: '1', // Allow the container to expand and use available space
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            scrollBehavior: 'smooth',
          }}
        >
          {options.map((option) => (
            <Button onClick={() => handleClick(option)} key={option} variant="outlined" sx={{ paddingX: 10, mx: 1 }}>
              {option}
            </Button>
          ))}
        </Box>

        <Divider orientation="vertical" flexItem />

        <IconButton
          color="primary"
          onClick={() => handleScroll(200)}
          sx={{
            mx: 1,
            position: 'absolute',
            right: 0,
            zIndex: 1,
            transition: 'opacity 0.3s',
            opacity: 1,
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </RootStyle>
  );
}
