import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

// import DatePicker from 'react-datepicker'; // Import the DatePicker component
import { styled } from '@mui/material/styles';
import { Stack, Input, Divider, IconButton, Box } from '@mui/material';
// import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'; // Import moment for date formatting
import uuidv4 from '../../../utils/uuidv4';
import Iconify from '../../../components/Iconify';
import { EmojiPicker } from '../../../components/emoji-picker';

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}));

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};

export default function ChatMessageInput({ disabled, onSend, isInteger2, isDate2, isPhone2 , isEmail2, onUndo }) {
 


  const fileInputRef = useRef(null);
  // const currentDate = new Date();
  // const currentYear = currentDate.getFullYear();
  // const currentMonth = currentDate.getMonth() + 1;
  // const currentDay = currentDate.getDate();
  const defaultDate = new Date();
  const [startDate, setStartDate] = useState(new Date(defaultDate));
  const [message, setMessage] = useState('');

 

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === '-' || event.key.toLowerCase() === 'e' || event.key === '.' || event.key === '+') {
      event.preventDefault();
    }
  };

  const isSpecialCharacter = (input) => {
    const regex = /^[a-zA-Z0-9\s]*$/; 
    return !regex.test(input);
  };


  const handleSend = () => {
   
    if(isEmail2) {
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
      if(!emailRegex.test(message)) {
        alert("Please enter a valid email address");
        return;
      }
    }

    if (isPhone2) {
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(message)) {
        alert("Please enter a valid 10-digit phone number");
        return;
      }
    }
    
    if (isDate2) {
      const currentYear = startDate.getFullYear();
  const currentMonth = startDate.getMonth() + 1;
  const currentDay = startDate.getDate();
      if (onSend) {
        onSend({
          messageId: uuidv4(),
          message: `${currentYear.toString()}/${currentMonth.toString()}/${currentDay.toString()}`,
          contentType: 'text',
          attachments: [],
          createdAt: new Date(),
          senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        });
      }
      setStartDate(defaultDate);
    } else {
      if (onSend) {
        let type = 'text';
        if(isInteger2) {
          if(isPhone2) {
            type = 'phone';
          } else {
            type = 'integer';
          }
        } 
        onSend({
          messageId: uuidv4(),
          message,
          contentType : type,
          attachments: [],
          createdAt: new Date(),
          senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        });
      }
      setMessage('');
    }
  };

  return (
    <>
      {isDate2 ? (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: 56 }}>
          {/* Allow to pick up dates from next year only */}
          <IconButton color="primary" onClick={onUndo} sx={{ mx: 1 }}>
            <Iconify icon="ic:round-undo" width={22} height={22} />
          </IconButton>
          <Divider orientation="vertical" flexItem />
          {/* <DatePicker onKeyUp={handleKeyUp} selected={startDate} onChange={(date) => setStartDate(new Date(date))}   dateFormat="dd/MM/yyyy" minDate={defaultDate}  */}
           {/* showYearPicker   */}
          {/* /> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        onKeyUp={handleKeyUp}
          views={['year', 'month', 'day']}
          value={startDate}
          onChange={(date)=>setStartDate(new Date(date))}
          renderInput={(props) => <TextField {...props} />}
          minDate={defaultDate}
        />
          </LocalizationProvider>
        
          <Divider orientation="vertical" flexItem />
          <IconButton color="primary" onClick={handleSend} sx={{ mx: 1 }}>
            <Iconify icon="ic:round-send" width={22} height={22} />
          </IconButton>
        </Box>
      ) : (
        <RootStyle>
          <IconButton color="primary" onClick={onUndo} sx={{ mr: 1.2 }}>
            <Iconify icon="ic:round-undo" width={17} height={17} />
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <Input
            sx={{ 
              fontFamily: 'aptos',
              padding: '0.5rem',
            }}
            disabled={disabled}
            fullWidth
            value={message}
            disableUnderline
            onKeyUp={handleKeyUp}
            onKeyDown={isInteger2 ? handleKeyDown : null}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            placeholder="Type here"
            type={isInteger2 ? 'number' : 'text'}
          />
          <Divider orientation="vertical" flexItem />
          <IconButton color="primary" disabled={!message} onClick={handleSend} sx={{ mx: 1 }}>
            <Iconify icon="ic:round-send" width={22} height={22} />
          </IconButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </RootStyle>
      )}
    </>
  );
}
