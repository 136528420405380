// webSocketSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: '',
  incommingData: [],
  userData: [],
};

const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    setWebSocketStatus: (state, action) => {
        state.status = action.payload;
    },
  },
});

const storeWSResponseSlice = createSlice({
    name: 'storeWSResponse',
    initialState,
    reducers: {
        updateWSResponse: (state, action) => {
            state.incommingData.push(action.payload);
        },
    },
});

const onSendMessageSlice = createSlice({
    name: 'onSendMessage',
    initialState,
    reducers: {
        onSendMessage: (state, action) => {
            state.userData.push(action.payload.message);
        }
    },
})

export const { setWebSocketStatus } = webSocketSlice.actions;
export const { updateWSResponse } = storeWSResponseSlice.actions;
export const { onSendMessage } = onSendMessageSlice.actions;
export const webSocketStatusReducer = webSocketSlice.reducer;
export const storeWSResponseReducer = storeWSResponseSlice.reducer;
export const onSendMessageReducer = onSendMessageSlice.reducer;
