import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
// @mui
import { Box, Divider, Stack, Button, Typography, Avatar, Skeleton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import uuidv4 from '../../../utils/uuidv4';
// redux
import { useSelector } from '../../../redux/store';
// routes
import { PATH_CHATBOT } from '../../../routes/paths';
// components

import ChatMessageList from './ChatMessageList';
import ChatMessageInput from './ChatMessageInput';
import ChatOptionsInput from './ChatOptionsInput';

// ---------------------------------------------------------------------- //
const URL = `https://api.richvikwealth.in`;
// const URL = 'http://localhost:3000';
const TOKEN = localStorage.getItem('accessToken');
const sessionId = uuidv4();

export default function ChatWindow() {
  const { pathname } = useLocation();
  let { chatbotSlug } = useParams();
  chatbotSlug = chatbotSlug.replace(/%20/g, ' ');

  // BOT INFO
  const [logo, setLogo] = useState('');
  const [chatbotName, setChatbotName] = useState('');
  const [chatBotId, setChatbotId] = useState('');

  // QUESTION INFO
  const [allQuestions, setAllQuestions] = useState([]);
  const [allError, setAllError] = useState([]);

  // CONVERSATION INFO
  const [loading, setLoading] = useState(false);
  const [conversationQueue, setConversationQueue] = useState([]);
  const [pendingConversation, setPendingConversation] = useState([]);
  const undoQueue = useRef([]);
  const tableResults = useRef([]);

  // CHAT FLOW
  const [started, setStarted] = useState(false);
  const selectedOptions = useSelector((state) => state.handleOptions);
  const selectedIndex = useRef(0);
  const responseQueue = useRef([]);

  // ABBRIVATIONS
  const [currTarget, setCurrTarget] = useState('');
  const [nextTarget, setNextTarget] = useState('');

  // TYPES
  const [isInteger, setInteger] = useState(false);
  const [isDate, setDate] = useState(false);
  const [isPhone, setPhone] = useState(false);
  const [isEmail, setEmail] = useState(false);

  // CONDITIONAL VALUES
  const prevValues = useRef(new Map());


  const ChatMessageListMemo = React.memo(ChatMessageList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const firstResponse = await axios.get(`${URL}/get-chatbot-by-slug/${chatbotSlug}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        });

        const chatBotId = firstResponse.data.data.chatBotId;
        setLogo(firstResponse.data.data.logo);
        setChatbotName(firstResponse.data.data.chatbotName);
        setChatbotId(chatBotId);

        const secondResponse = await axios.get(`${URL}/get-questionare-by-id/${chatBotId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          },
        });
        setAllQuestions(secondResponse.data.initialQuestion);

        if(chatbotSlug !== 'richvik-goal') {
          const thirdResponse = await axios.get(`${URL}/get-text-result-errors/${chatBotId}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TOKEN}`,
            },
          });
          setAllError(thirdResponse.data.testResultErrors);
         }

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the async function
  }, [chatbotSlug]);

  useEffect(() => {
    if (currTarget !== '') getNextQuestion();
  }, [currTarget]);

  const fetchTable = async () => {
    try {
      const body = {
        slug: chatbotSlug,
        sessionId,
        nextTextTarget: currTarget,
        responses: responseQueue.current[selectedIndex.current],
      };

      selectedIndex.current += 1;
      if (selectedIndex.current < selectedOptions.length) {
        getQuestionForMultiSelect();
      }

      const response = await axios.post(`${URL}/get-table-result`, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
      });

      if(response.status === 200) {
        for(let i = 0 ; i < response.data.question.length ; i+=1) {
          if (response.data.question[i].length > 1) {
            response.data.question[i].splice(1, 1);
          }
        }
      }

      // console.log("TABULLLLLLLLLL" , response)
      if (tableResults.current.length > 0) {
        tableResults.current[0].question = [...tableResults.current[0].question, response.data.question[1]];
      } else {
        response.data.type = 'table';
        tableResults.current = [...tableResults.current, response.data];
      }
      // console.log("TABULLLLLLLLLL" , tableResults,response.data)
      
      if (selectedIndex.current >= selectedOptions.length) {
        const isDelay = chatbotSlug === 'richvik-goal';
        updateConversationQueue(tableResults.current[0], true, false, isDelay);
        setCurrTarget(response.data.nextTarget[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendPdf = async () => {
    try {
      const body = {
        sessionId,
        name: responseQueue.current[selectedIndex.current][0].response,
        phone: responseQueue.current[selectedIndex.current][1].response,
        email: responseQueue.current[selectedIndex.current][2].response,
        newTable: tableResults.current[0].message,
        response: responseQueue.current,
        botId: chatBotId,
      };
      // console.log('body', body);
      const response = await axios.post(`${URL}/get-pdf-by-email`, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
      });
      if (response && response.status === 200) {
        updateConversationQueue(
          {
            type: 'text',
            question: response.data.message,
            createdAt: new Date(),
            senderName: chatbotName,
            senderId: chatBotId,
            target: ['End'],
          },
          true
        );
      }
    } catch (error) {
      console.error(error);
    }
    finally{
        applyPendingConversation();
    }
  };

  const sendResult = async () => {
    try {

      const responses = [];

      for (let i = 0; i < responseQueue.current[selectedIndex.current].length; i += 1) {
        let temp = [];
        if(responseQueue.current[selectedIndex.current][i].target.length > 1) {
           temp = [responseQueue.current[selectedIndex.current][i].target[responseQueue.current[selectedIndex.current][i].target.length - 1].conditionalTarget];
        } else {
          temp = responseQueue.current[selectedIndex.current][i].target[0];
        }
        responses.push({
          abbreviation: responseQueue.current[selectedIndex.current][i].abbreviation,
          question: responseQueue.current[selectedIndex.current][i].question,
          response: responseQueue.current[selectedIndex.current][i].response,
          target: temp,
        });
      }

      const body = {
        sessionId,
        responses
      };

      const response = await axios.post(`${URL}/get-text-single`, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`,
        },
      });
      // console.log("repsonse" , response)
      if (response && response.status === 200) {
        updateConversationQueue(
          {
            type: 'text',
            question: response.data.data.data.conversation.message,
            message: response.data.data.data.conversation.message,
            createdAt: new Date(),
            senderName: chatbotName,
            senderId: chatBotId,
            target: ['End'],
          },
          true
        );

      }



    } catch (error) {
      console.error(error);
    }
  };

  // GENERAL TO UPDATE QUEUE
  const updateConversationQueue = (data, bot = false , isError=false,isDelay=false) => {
    // console.log(data)
    if (bot) {
      data.senderName = chatbotName;
      data.senderId = chatBotId;
      if(isError) {
        data.message = data.name;
        data.question = data.name;
      } else {
        data.message = data.question;
      }
      data.createdAt = new Date();
      if (!isError && data.type !== 'table') {
        if(undoQueue.current[undoQueue.current.length - 1]?.abbreviation !== data.abbreviation) {
          undoQueue.current = [...undoQueue.current, data];
        }
      }
    }
    if(isDelay){
        updatePendingConversation(data);
    }
    else{
      setConversationQueue((prev) => [...prev, data]);
    }
    if(isError) {
      setCurrTarget(data.target[0])
    }
  };

  const updatePendingConversation = (data) => {
    setPendingConversation([...pendingConversation, data]);
  }
  const applyPendingConversation = (...args) => {
      if(pendingConversation.length === 0) return;
      setConversationQueue((prev) => [...prev, pendingConversation[pendingConversation.length-1]]);
  }


  // ON USER RESPONSE
  const handleSendMessage = (message) => {
    updateConversationQueue(message);
    let abrri = '';
    if (currTarget === '') {
      abrri = chatbotSlug
        .split('-')
        .map((word, index) => {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
    } else {
      abrri = currTarget;
    }
    const response = {
      abbreviation: abrri,
      question: conversationQueue[conversationQueue.length - 1].question,
      response: message.message,
      target: nextTarget,
    };
    responseQueue.current[selectedIndex.current] = [...(responseQueue.current[selectedIndex.current] || []), response];
    
    if(chatbotSlug !== 'richvik-goal' && nextTarget.length > 1) {

      if(conversationQueue.length === 1) {
        prevValues.current.set(conversationQueue[0].abbreviation , response.response)
      }
      else {
        prevValues.current.set(currTarget.toString() , response.response)
      }
        for (let i = 0; i < nextTarget.length; i+=1) {
          if (nextTarget[i].conditionalField.includes("less")) {

            if(nextTarget[i].conditionalValue[0] === '@') {
              const k = prevValues.current.get(nextTarget[i].conditionalValue.slice(1));
              if(parseInt(k ,10) > parseInt(response.response , 10)) {
                setCurrTarget(nextTarget[i].conditionalTarget);
                break; 
              }
            }

            else if (parseInt(nextTarget[i].conditionalValue , 10) > parseInt(response.response , 10)) {
              setCurrTarget(nextTarget[i].conditionalTarget);
              break;
            }
          } 
          if (nextTarget[i].conditionalField.includes("greater")) {

            if(nextTarget[i].conditionalValue[0] === '@') {
              const k = prevValues.current.get(nextTarget[i].conditionalValue.slice(1));
              if(parseInt(k ,10) < parseInt(response.response , 10)) {
                setCurrTarget(nextTarget[i].conditionalTarget);
                break; 
              }
            }

            else if (parseInt(nextTarget[i].conditionalValue, 10) < parseInt(response.response, 10)) {
              setCurrTarget(nextTarget[i].conditionalTarget);
              break; 
            }


          } 
          if (nextTarget[i].conditionalField.includes("equate")) {

            if(nextTarget[i].conditionalValue[0] === '@') {
              const k = prevValues.current.get(nextTarget[i].conditionalValue.slice(1));
              if(parseInt(k ,10) === parseInt(response.response , 10)) {
                setCurrTarget(nextTarget[i].conditionalTarget);
                break; 
              }
            }

            else if (parseInt(nextTarget[i].conditionalValue, 10) === parseInt(response.response, 10)) {
              setCurrTarget(nextTarget[i].conditionalTarget);
              break;
            }
          } 
          if(i === nextTarget.length - 1) {
            setCurrTarget(nextTarget[i].conditionalTarget);
            break; 
          }
        }

  
    } else {
      setCurrTarget(nextTarget[0]);
    }
  };

    // GET NEXT TARGET
  const getNextQuestion = () => {

    console.log({currTarget})
    const nextQuestion = allQuestions.filter((item) => item.abbreviation === currTarget);

    if (nextQuestion.length === 0) {

      if(allError.length !== 0) { 
        const nextQuestion = allError.filter((item) => item.abbreviation === currTarget);

        if(nextQuestion.length === 0) {
          return;
        }

        if(nextQuestion[0].abbreviation === "RHLVPresentHLVSecond") {
          sendResult()
          return;
        }
        
        if(nextQuestion[0].name === "null") {
          console.log("nulla" , nextQuestion[0])
          setCurrTarget(nextQuestion[0].target[0]);
        }
        else {
          console.log("not nulla" , nextQuestion[0])
          updateConversationQueue(nextQuestion[0], true , true);
        }
      }

      if(chatbotSlug === 'richvik-goal') {

        if (currTarget.includes('Note')) {
          sendPdf();
        } else {
          fetchTable();
        } 
       }

    } 
    else {
      if (conversationQueue.length > 0 && conversationQueue[conversationQueue.length - 1].message === nextQuestion[0].question) {
        return;
      }
      setPhone(nextQuestion[0].abbreviation.includes('Phone'));
      setEmail(nextQuestion[0].abbreviation.includes('Email'));
      setDate(nextQuestion[0].type === 'date');
      setInteger(nextQuestion[0].type === 'integer');
      setNextTarget(nextQuestion[0].target);
      updateConversationQueue(nextQuestion[0], true);
    }
  };

  const getQuestionForMultiSelect = () => {
    const abbri = selectedOptions[selectedIndex.current]
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
    setCurrTarget(abbri);
  };

  // MultiOptions
  const handleSendMultiOption = () => {
    handleSendMessage({
      message: selectedOptions.join(', '),
      type: 'text',
      attachments: [],
      createdAt: new Date(),
      senderName: 'You',
      senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
    });

    getQuestionForMultiSelect();
  };

  const handleUndo = () => {

    if(chatbotSlug !== 'richvik-goal') { 
      if (undoQueue.current.length <= 1) return;
    }

    if (undoQueue.current.length === 0) return;

    undoQueue.current = undoQueue.current.slice(0, undoQueue.current.length - 1);
    if (undoQueue.current.length > 1) {
      let i = conversationQueue.length - 1;
      for (i; i >= 0; i -= 1) {
        if (conversationQueue[i] === undoQueue.current[undoQueue.current.length - 1]) {
          break;
        }
      }
      if (responseQueue.current[selectedIndex.current] && responseQueue.current[selectedIndex.current].length > 0) {
        responseQueue.current[selectedIndex.current] = responseQueue.current[selectedIndex.current].slice(0,responseQueue.current[selectedIndex.current].length - 1);
      } else {
        if (tableResults.current[0].question.length >= 2) {
          tableResults.current[0].question = tableResults.current[0].question.slice( 0,tableResults.current[0].question.length - 1);
        } else tableResults.current = [];

        if (responseQueue.current.length === selectedIndex.current) {
          selectedIndex.current -= 1;
          responseQueue.current[selectedIndex.current] = responseQueue.current[selectedIndex.current].slice(0,responseQueue.current[selectedIndex.current].length - 1);
        } else {
          responseQueue.current = responseQueue.current.slice(0, responseQueue.current.length - 1);
          selectedIndex.current -= 1;
          responseQueue.current[selectedIndex.current] = responseQueue.current[selectedIndex.current].slice(0,responseQueue.current[selectedIndex.current].length - 1);
        }
      }

      setCurrTarget(undoQueue.current[undoQueue.current.length - 1].abbreviation);
      setNextTarget(undoQueue.current[undoQueue.current.length - 1].target);
      undoQueue.current = undoQueue.current.slice(0, undoQueue.current.length - 1);
      setConversationQueue(conversationQueue.slice(0, i));
    } else {
      setCurrTarget(undoQueue.current[undoQueue.current.length - 1].abbreviation);
      setNextTarget(undoQueue.current[undoQueue.current.length - 1].target);
      responseQueue.current = [];
      setConversationQueue(conversationQueue.slice(0, 1));
    }
  };

  const startHandle = () => {
    const initial = allQuestions.filter((item) => item.isInitialQs === true); 
    setNextTarget(initial[0].target);
    updateConversationQueue(initial[0], true);
    setStarted(true);

    if(chatbotSlug === 'richvik-hlv') {
      setCurrTarget(initial[0].target[0]);
      getNextQuestion();
    }

  };

  const reloadPage = () => window.location.reload(true);

  console.log('====================================');
  console.log('conversationQueue', conversationQueue);
  console.log('allQuestions', allQuestions);
  console.log('allError', allError);
  console.log('selectedOptions', selectedOptions);
  console.log('responseQueue', responseQueue.current);
  console.log('currTarget', currTarget);
  console.log('nextTarget', nextTarget);
  console.log('tableResults', tableResults.current);
  console.log('pendingConversation', pendingConversation);
  console.log('undoQueue', undoQueue.current);
  console.log('selectedIndex', selectedIndex.current);
  console.log("prevValues" ,prevValues.current)
  console.log('====================================');

  return (
    <>
      <Stack sx={{ flexGrow: 1, minWidth: '1px', fontFamily: 'aptos, cursive' }}>
        {loading ? (
          // Loading Skeleton
          <Box
            sx={{
              p: '0.8rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              bgcolor: 'primary.lighter',
            }}
          >
            <Skeleton variant="circular" width={56} height={56} animation="wave" />
            <Skeleton
              variant="rectangular"
              width={250}
              sx={{ marginTop: '10px', borderRadius: '10px' }}
              height={32}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width={50}
              sx={{ marginTop: '10px', borderRadius: '10px' }}
              height={32}
              animation="wave"
            />
          </Box>
        ) : (
          // Your content when not loading
          <Box
            sx={{
              p: '0.8rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              bgcolor: 'primary.lighter',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'left', alignContent: 'flex-start', gap: '1rem' }}>
              <Avatar alt="Remy Sharp" src={logo} />
              <Typography variant="h4" gutterBottom fontFamily={'Playfair Display, serif'}>
                {chatbotName?.toUpperCase()}
              </Typography>
            </Box>
            <Box>
              <Button onClick={reloadPage} color="secondary">
                <RefreshIcon />
              </Button>
            </Box>
          </Box>
        )}

        <Divider />

        <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
          <Stack sx={{ flexGrow: 1 }}>
            {started && conversationQueue.length > 0 && (
              <ChatMessageListMemo
                started={started}
                logo={logo}
                conversation={conversationQueue}
                options={conversationQueue[0].options}
                botName={chatbotName}
              />
            )}

            <Divider />

            {started && conversationQueue[conversationQueue.length - 1].type !== 'multiselect' && (
              <ChatMessageInput
                onSend={handleSendMessage}
                disabled={pathname === PATH_CHATBOT.chatbot('insurance-Bot')}
                onUndo={handleUndo}
                isInteger2={isInteger}
                isDate2={isDate}
                isPhone2={isPhone}
                isEmail2={isEmail}
              />
            )}

            {/* {
              started && conversationQueue[conversationQueue.length-1].type === 'multiselect' && started &&
              <ChatOptionsInput
                conversationId="8864c717-587d-472a-929a-8e5f298024da-0"
                disabled={pathname === PATH_CHATBOT.chatbot("insurance-Bot")}
                options={conversationQueue[0].options}
              />

          } 
        */}

            {started &&
              conversationQueue &&
              conversationQueue[conversationQueue.length - 1].type === 'multiselect' &&
              selectedOptions.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                  <Button onClick={handleSendMultiOption} variant="outlined" sx={{ mx: 1, fontFamily: 'aptos' }}>
                    Proceed
                  </Button>
                </Box>
              )}

            {loading ? (
              // Loading Skeleton
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <Skeleton variant="rectangular" width={120} height={36} animation="wave" />
              </Box>
            ) : (
              // Your content when not loading
              !started && (
                <Box sx={{ display: 'flex', justifyContent: 'center', "marginTop" : "auto", alignItems: 'center', p: 2 }}>
                  <Button onClick={startHandle} variant="outlined" sx={{ mx: 1, fontFamily: 'aptos' }}>
                    Let's Start
                  </Button>
                </Box>
              )
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}



